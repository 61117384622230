import Button from './Button'
import Heading from './Heading'
import Wrap from './Wrap'

export default function Header() {
  return (
    <div className="relative text-center bg-teal-800">
      <div
        className="bg-cover bg-center absolute top-0 left-0 w-full h-full opacity-50"
        style={{ backgroundImage: 'url(/header.jpg)' }}
      ></div>
      <Wrap>
        <div className="relative flex flex-col items-center">
          <Heading
            narrow
            type={1}
            white
            margin={false}
            className="my-16 xs:my-20 lg:my-28"
          >
            Haal meer uit uw orders door ze digitaal te verwerken.
          </Heading>
          <div className="-mb-6 xs:-mb-7">
            <Button to="/contact">Neem contact op</Button>
          </div>
        </div>
      </Wrap>
    </div>
  )
}
