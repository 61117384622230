export default function SellingPoints() {
  return (
    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 xl:gap-12">
      <SellingPoint>
        <Cloud />
        <h4>Veilige data</h4>
        <p>
          Veiligheid en afscherming van uw gegevens staan bij ons voorop. Daarom
          zijn al uw gegevens en documenten bij EDI-Central versleuteld.
        </p>
      </SellingPoint>
      <SellingPoint>
        <Filter />
        <h4>Eindeloze mogelijkheden</h4>
        <p>
          We ondersteunen een onbeperkt aantal handelspartners, onbeperkte
          soorten berichten en diverse protocollen om mee uit te wisselen.
        </p>
      </SellingPoint>
      <SellingPoint>
        <Heart />
        <h4>Gedreven door passie</h4>
        <p>
          EDI is een breed en complex proces, ons doel is om dit voor iedereen
          toegankelijk te maken. Wij zijn pas gelukkig als u zelf grip heeft op
          uw orderstroom.
        </p>
      </SellingPoint>
    </div>
  )
}

const SellingPoint = ({ children }) => (
  <div className="shadow-xl rounded-xl text-center px-6 xs:px-8 py-8 xs:py-10 bg-white flex flex-col items-center">
    <div className="mb-6 xs:mb-8 text-white bg-teal-400 p-4 rounded-xl shadow-md">
      {children[0]}
    </div>
    <div className="mb-2 leading-tight text-lg font-bold text-gray-800">
      {children[1]}
    </div>
    <div className="text-gray-600 leading-relaxed">{children[2]}</div>
  </div>
)

const Heart = () => (
  <svg
    className="w-6 h-6"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
  </svg>
)

const Filter = () => (
  <svg
    className="w-6 h-6"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
  </svg>
)

const Cloud = () => (
  <svg
    className="w-6 h-6"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M18 10h-1.26A8 8 0 1 0 9 20h9a5 5 0 0 0 0-10z"></path>
  </svg>
)
