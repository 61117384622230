import React, { useState } from 'react'

export default function Table({ children }) {
  const [gradient, showGradient] = useState(true)

  return (
    <div className="relative">
      <div
        onScroll={gradient ? () => showGradient(false) : undefined}
        className="overflow-x-scroll scrolling-touch lg:overflow-x-visible custom-scrollbar w-overflow-x-5 md:w-overflow-x-10 lg:w-full -ml-5 md:-ml-10 lg:ml-0"
      >
        {children}
      </div>
      {gradient && (
        <div className="lg:hidden bg-gradient-to-r from-white-transparent to-white absolute right-0 -mr-5 md:-mr-10 top-0 h-full w-48 xs:w-64 pointer-events-none"></div>
      )}
    </div>
  )
}
