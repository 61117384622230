const CheckCircle = () => (
  <svg
    className="flex-none w-6 lg:w-7 h-6 lg:h-7 text-green-400 stroke-current"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
)

export default CheckCircle
