import Row from './Row'

export default function MainFeatures() {
  return (
    <>
      <Row
        label="Maandelijks abonnement"
        options={['€ 25 p/m', '€ 75 p/m', '€ 150 p/m', '€ 750 p/m']}
      />
      <Row label="Maandelijks opzegbaar" options={[true, true, true, true]} />
      <Row
        label="Eenmalige aansluitkosten abonnement"
        options={['€ 250', '€ 500', '€ 500', '€ 500']}
      />
    </>
  )
}
