import Wrap from './Wrap'
import Table from './Table'
import Section from './Section'
import Heading from './Heading'
import Button from './Button'
import Intro from './Intro'
import Plans from './Plans'
import MainFeatures from './MainFeatures'

export default function Pricing() {
  return (
    <Section>
      <Wrap>
        <Section>
          <Heading center>Abonnementen</Heading>
          <Intro>
            Voor ieder type klant een passend pakket. Bekijk de abonnementen
            pagina voor aanvullende details.
          </Intro>
        </Section>
        <Section>
          <Table>
            <Plans />
            <div className="lg:shadow-xl lg:rounded-b-xl lg:rounded-tl-xl">
              <MainFeatures />
            </div>
          </Table>
        </Section>
        <Button to="/abonnementen">Bekijk alle features</Button>
      </Wrap>
    </Section>
  )
}
