const InfoCircle = ({ onClick }) => (
  <svg
    onClick={onClick}
    className="ml-4 flex-none w-5 h-5 text-gray-400 cursor-pointer stroke-current hover:text-blue-400"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
)

export default InfoCircle
