import React, { useState } from 'react'
import CheckCircle from '../icons/CheckCircle'
import CrossCircle from '../icons/CrossCircle'
import Grid from './Grid'
import InfoCircle from '../icons/InfoCircle'

export default function Row({
  label,
  wide,
  indent,
  thin,
  description,
  options,
}) {
  const [collapsed, collapse] = useState(false)
  const data = Array.isArray(options) ? options : [options]

  return (
    <Grid
      className={`${
        !label && !options ? 'h-4 lg:h-6' : label ? '' : 'h-10 lg:h-12'
      } odd:bg-gray-50 border-b lg:last:border-b-0 lg:last:rounded-b-xl first:border-t`}
    >
      {label && (
        <div
          className={`${label && !options ? 'font-bold' : ''} ${
            indent ? 'pl-8' : 'pl-4'
          } ${
            wide ? 'w-1/3' : 'w-1/4'
          } pr-4 flex-none flex flex-col text-gray-800 text-sm py-3 lg:py-4`}
        >
          <div className="flex justify-between items-center">
            <div>{label}</div>
            {description && !collapsed && (
              <InfoCircle onClick={() => collapse(true)} />
            )}
          </div>
          {collapsed && <div className="text-xs mt-2">{description}</div>}
        </div>
      )}
      {options && <Cols data={data} thin={thin} />}
    </Grid>
  )
}

const Cols = ({ data, thin }) => (
  <>
    {data.map((option, key) => (
      <Col key={key}>
        {option === true ? (
          <CheckCircle />
        ) : option === false ? (
          <CrossCircle />
        ) : (
          <Text thin={thin}>{option}</Text>
        )}
      </Col>
    ))}
  </>
)

const Col = ({ children }) => (
  <div className="w-15/80 flex flex-grow justify-center items-center">
    {children}
  </div>
)

const Text = ({ children, thin }) => (
  <div className={`${thin ? '' : 'font-bold'} text-sm lg:text-base`}>
    {children}
  </div>
)
