import Menu from '../components/Menu'
import SellingPoints from '../components/SellingPoints'
import Section from '../components/Section'
import Wrap from '../components/Wrap'
import Intro from '../components/Intro'
import Heading from '../components/Heading'
import Pricing from '../components/Pricing'
import Footer from '../components/Footer'
import Header from '../components/Header'

export default function Home() {
  return (
    <>
      <Menu />
      <Header />
      <Section large>
        <Wrap blue top>
          <Section>
            <Heading center>Waarom EDI-Central?</Heading>
            <Intro small>
              Het is in ons aller belang om meer en betere elektronische
              orderstromen op gang te brengen. Continue doorontwikkeling van ons
              platform met als doel het centraal beschikbaar stellen van
              processen, communicatie en instellingen voor alle betrokken
              partners.
            </Intro>
          </Section>
          <SellingPoints />
        </Wrap>
      </Section>
      <Pricing />
      <Footer />
    </>
  )
}
