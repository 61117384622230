import cn from '../utils/cn'

export default function Grid({ children, className, whiteBorder }) {
  return (
    <div
      className={cn(
        className,
        whiteBorder && 'divide-white',
        'flex divide-x w-240 md:w-320 lg:w-auto'
      )}
    >
      {children}
    </div>
  )
}
