import cn from '../utils/cn'
import Grid from './Grid'

export default function Plans() {
  return (
    <Grid whiteBorder>
      <div className="w-1/4 flex-none flex items-end">
        <h3 className="text-gray-800 font-semibold text-xl lg:text-2xl ml-4 mb-4 lg:mb-6">
          Features
        </h3>
      </div>
      <Plan
        name="Starter"
        description="Enkel met EDI-Central Client, beperkt tot interne handelspartners"
        className="bg-purple-500 lg:rounded-tl-xl"
      />
      <Plan
        name="Basic"
        description="Alle protocollen, naar zowel interne als externe handelspartners"
        className="bg-indigo-500"
      />
      <Plan
        name="Pro"
        description="FTP(S) hosting + maatwerk mogelijkheden"
        className="bg-blue-500"
      />
      <Plan
        name="Enterprise"
        description="Priority support + standaard 5 handelsondernemingen inbegrepen"
        className="bg-teal-500 lg:rounded-tr-xl"
      />
    </Grid>
  )
}

const Plan = ({ className, name, description }) => (
  <div
    className={cn(
      className,
      'text-white py-6 lg:py-8 text-center w-15/80 flex-none'
    )}
  >
    <h3 className="mb-1 lg:mb-2 font-semibold text-xl lg:text-2xl">{name}</h3>
    <p className="px-4 lg:px-6 text-sm">{description}</p>
  </div>
)
